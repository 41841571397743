import { IndexedDbModel } from '@capturum/complete';
import { SyncErrorEntityType } from '@core/models/sync-error.model';

export class IndexDbSyncError extends IndexedDbModel {
  // Configuration
  public entity = 'sync_error';
  public table = 'sync_errors';
  public schema = 'id';
  public sync = true;

  // Fields
  public entity_type: SyncErrorEntityType;
  public message: string[];
  public created?: string | Date;

  // Constructor
  constructor(attributes: any) {
    super(attributes);
  }
}
