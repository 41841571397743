import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService, ApiSingleResult } from '@capturum/api';
import { Observable } from 'rxjs';
import { SyncResponse } from '@capturum/complete';

@Injectable({
  providedIn: 'root',
})
export class ModuleService extends ApiService<any> {
  protected endpoint = 'module';

  constructor(public apiHttp: ApiHttpService) {
    super(apiHttp);
  }

  public sync(data: any): Observable<ApiSingleResult<SyncResponse>> {
    return this.apiHttp.post(`/${this.endpoint}/synchronize`, data);
  }
}
