import { Injectable } from '@angular/core';
import { SyncErrorType, SyncResponse, UserService } from '@capturum/complete';
import { SyncError, SyncErrorEntityType } from '@core/models/sync-error.model';
import { v4 as uuid } from 'uuid';
import { combineLatest, from, Observable, of } from 'rxjs';
import { IndexDbSyncError } from '@core/indexDb/model/indexDb-sync-error.model';
import { ObservationService } from '@core/api/observation.service';
import { TranslateService } from '@ngx-translate/core';
import { BlockService } from '@core/api/block.service';
import { map, take } from 'rxjs/operators';
import { FilterMatchMode, MapItem } from '@capturum/ui/api';
import { PackageService } from '@core/api/package.service';
import { ApiHttpService } from '@capturum/api';
import { HarvestService } from '@core/api/harvest.service';

@Injectable({
  providedIn: 'root',
})
export class SyncErrorsService {
  constructor(
    private observationService: ObservationService,
    private blockService: BlockService,
    private packageService: PackageService,
    private harvestService: HarvestService,
    private userService: UserService,
    private translateService: TranslateService,
    public apiHttp: ApiHttpService,
  ) {}

  public createOffline(data: any): Observable<string> {
    return from(IndexDbSyncError.query().add(data));
  }

  public getTotalAmount(): Observable<number> {
    return from(IndexDbSyncError.query().count());
  }

  public removeOffline(data: any): Observable<any> {
    if (Array.isArray(data)) {
      return from(IndexDbSyncError.query().bulkDelete(data));
    } else {
      return from(IndexDbSyncError.query().delete(data));
    }
  }

  public getStoredSyncErrors(): Observable<IndexDbSyncError[]> {
    return from(IndexDbSyncError.query().toArray());
  }

  public parseSyncErrors(data: SyncResponse): Observable<SyncError<any>[]> {
    let errors = [];

    Object.keys(data).forEach((key) => {
      if (data[key].errors) {
        errors = [
          ...errors,
          ...data[key].errors.map((errorItem) => {
            return {
              id: uuid(),
              entity_type: key,
              created: new Date(),
              data: errorItem.data,
              message: this.extractMessage(errorItem.type, errorItem[errorItem.type]),
            } as SyncError<any>;
          }),
        ];
      }
    });

    return combineLatest(this.parseValues(errors)).pipe(
      map((values) => {
        return values.map((value, index) => {
          if (value.length) {
            return {
              ...errors[index],
              values: value,
            };
          }

          return null;
        });
      }),
    );
  }

  public parseValues(errors: SyncError<any>[]): Observable<MapItem[]>[] {
    if (errors.length === 0) {
      return [of([])];
    }

    return errors.map((error) => {
      switch (error.entity_type) {
        case SyncErrorEntityType.observations:
          return this.blockService.get(error.data.observationable_id, { include: ['farm'] }).pipe(
            take(1),
            map((response) => {
              return this.getObservationTranslatedError(response, error.data);
            }),
          );
        case SyncErrorEntityType.harvests:
          return combineLatest([this.getPackage(error.data), this.getBlock(error.data), this.getUser(error.data)]).pipe(
            take(1),
            map(([pack, block, user]) => {
              const translatedError: MapItem[] = [
                { ...block },
                { ...pack },
                { ...user },
                {
                  label: this.translateService.instant('dxp.harvest.cut_date_at.label'),
                  value: error?.data?.cut_date_at,
                },
                {
                  label: this.translateService.instant('dxp.harvest.amount.label'),
                  value: error?.data?.amount,
                },
              ];

              return translatedError;
            }),
          );
        default:
          break;
      }
    });
  }

  public removeSyncedItems(data: SyncResponse): Observable<any[]> {
    let observables: Observable<any>[] = [];

    Object.keys(data).forEach((key) => {
      if (data[key].mutated) {
        const ids = data[key].mutated.map((mutatedItem) => {
          return mutatedItem.id;
        });

        if (ids.length) {
          switch (key) {
            case SyncErrorEntityType.observations:
              observables = [...observables, this.observationService.removeOffline(ids)];
              break;
            case SyncErrorEntityType.harvests:
              observables = [...observables, this.harvestService.removeOffline(ids)];
              break;
            default:
              console.warn(`Please provide scenario for <<${key}>> sync action`);
              break;
          }
        }
      }
    });

    observables = observables.length ? observables : [of(null)];

    return combineLatest(observables);
  }

  private extractMessage(type: SyncErrorType, data: any): string[] {
    switch (type) {
      case SyncErrorType.EXCEPTION:
        return [data.message];
      case SyncErrorType.VALIDATION:
        return Object.keys(data).map((key) => {
          return data[key];
        });
      default:
        break;
    }
  }

  private getObservationTranslatedError(response: any, data: any): MapItem[] {
    const translatedError: MapItem[] = [
      {
        label: this.translateService.instant('dxp.observation.farm.label'),
        value: response.farm.name,
      },
      {
        label: this.translateService.instant('dxp.observation.block.label'),
        value: response.code,
      },
      {
        label: this.translateService.instant('dxp.observation.type_base_data_value_id.label'),
        value: this.translateService.instant(`base-data.${data.type_base_data_value_id}`),
      },
      {
        label: this.translateService.instant('dxp.observation.value.label'),
        value: data.value,
      },
      {
        label: this.translateService.instant('dxp.observation.remark.label'),
        value: data.remark,
      },
    ];

    return translatedError;
  }

  private getPackage(data: any): Observable<MapItem> {
    return this.packageService
      .index({
        filters: [
          {
            field: 'id',
            value: data.package_id,
            operator: FilterMatchMode.EQUALS,
          },
        ],
      })
      .pipe(
        take(1),
        map((response) => {
          const packageItem: MapItem = {
            label: this.translateService.instant('dxp.harvest.package_id.label'),
            value: response.data.length === 1 ? response.data[0].code : '-',
          };

          return packageItem;
        }),
      );
  }

  private getBlock(data: any): Observable<MapItem> {
    return this.blockService
      .index({
        filters: [
          {
            field: 'id',
            value: data.block_id,
            operator: FilterMatchMode.EQUALS,
          },
        ],
      })
      .pipe(
        take(1),
        map((response) => {
          const blockItem: MapItem = {
            label: this.translateService.instant('dxp.harvest.block_id.label'),
            value: response.data.length === 1 ? response.data[0].code : '-',
          };

          return blockItem;
        }),
      );
  }

  private getUser(data: any): Observable<MapItem> {
    return this.userService
      .index({
        filters: [
          {
            field: 'id',
            value: data.harvester_user_id,
            operator: FilterMatchMode.EQUALS,
          },
        ],
      })
      .pipe(
        take(1),
        map((response) => {
          const packageItem: MapItem = {
            label: this.translateService.instant('dxp.mobile-app.harvest.scan.harvester.label'),
            value: response.data.length === 1 ? response.data[0].name : '-',
          };

          return packageItem;
        }),
      );
  }
}
