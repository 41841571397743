import { MapItem } from '@capturum/ui/api';

export interface SyncError<Model> {
  id: string;
  entity_type: SyncErrorEntityType;
  message: string[];
  created?: string | Date;
  data?: Model;
  values?: MapItem[];
}

export enum SyncErrorEntityType {
  observations = 'observations',
  harvests = 'harvests',
}
